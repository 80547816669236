/* React */ 
import React, { Component } from "react";

/* Plugins */
import Select from "react-dropdown-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell} from "react-sticky-table";
import Moment from "react-moment";

/* Redux */
import { StudentProgressActions } from "../../../../__actions/student_progress.actions";
import {mapAnddispatchActionsToProps, toggleShowModal, checkUserCapabilities } from "../../../../__helpers/helpers";
import { connect } from "react-redux";

/* Components */
import CopyToClipboardComponent from "../../global/components/copy_clipboard.component";
import ColumnsVisibilityPopover from "../../global/components/columns_visibility.component.jsx";
import TrackHistoryComponent from "./track_history.component";


import StudentAccessProfile from "../../global/modals/student_access_profile.modal";
import { StudentAccessActions } from "../../../../__actions/student_access.actions";
import { ADMIN_PAGES } from "../../../../__config/constants.js";

/* CSS */
import "./stack_table.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: December 8, 2021
*/
class ProgramTableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_student_profile_modal: false,
            selected_applicant_id: null,
            student_profile_data: [],
            offered_stack_schedules: [],
            sort_config: null,
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            grad_validation_dropdown_options: [
                {
                    value: 1,
                    label: "Yes",
                },
                {
                    value: 0,
                    label: "No",
                },
                {
                    value: null,
                    label: "N/A",
                }
            ],
            selected_name_sort_config: undefined,
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            is_update_student_profile: false,
            ap_options: [
                { value: 0, label: "None", bg_color: "green_bg" },
                { value: 1, label: "AP-1", bg_color: "red_bg"},
                { value: 2, label: "AIP", bg_color: "gray_bg" },
                { value: 3, label: "AP-2", bg_color: "gray_bg" }, /* TODO: Need to change the bg_color */
                { value: 4, label: "AP-3", bg_color: "gray_bg" }, /* TODO: Need to change the bg_color */
                { value: 5, label: "GS", bg_color: "gray_bg" } /* TODO: Need to change the bg_color */
            ],
        }
    }

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: May 9, 2022
    * @function
    * @memberOf ProgramTableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Jerwin, updated by Mel, CE, Jomar
    */
     renderTableHead = (table_head, data = []) => {

        if(table_head.name === "Name"){ 
            let { selected_name_sort_config } = this.state;
            let total_data_count = `(${this.props.student_progress.total_results|| 0})`;

            /* Get the the selected_sort as selected_name_sort_config */
            selected_name_sort_config = this.props?.selected_sort;

            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                return( <div id="name_sort_table_head">
                            {selected_name_sort_config?.title || "Name"}  
                            {total_data_count}

                            <div id="name_sort_icon">
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`} 
                                    icon={["fas", "caret-up" ]} />
                                
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`} 
                                    icon={["fas", "caret-down" ]} />
                            </div>
                        </div>);
            }
            else{
                return( <React.Fragment>
                            Name {total_data_count}
                        </React.Fragment>);
            }
        }

        /* Normal table head */ 
        return table_head.name;
    }

    /**
    * DOCU:  This are the data that will be used to determine the user's status <br>
    * Last Updated Date: AUgust 08, 2023
    * @function
    * @memberOf ProgramTableDataComponent
    * @author Jerwin, Updated by: Jomar
    */
    renderUserStatus = (status) => {
        /* TODO: To be transferred to _config/constants.js onced the data is final */ 
        const USER_STATUS = 
        {
            3.0: {name: "Accepted", bg_color: "gray" },
            3.1: {name: "Unresponsive", bg_color: "gray" },
            3.2: {name: "Not Interested", bg_color: "gray" },
            3.3: {name: "Rejected", bg_color: "red" },
            4: {name: "Paid Deposit", bg_color: "green" },
            4.2: {name: "Current", bg_color: "green" },
            4.6: {name: "Alumni", bg_color: "green" },
            4.7: {name: "Transferred", bg_color: "green" },
            4.1: {name: "Upcoming", bg_color: "gray" },
            4.3: {name: "Pending", bg_color: "yellow" },
            4.4: {name: "Incomplete", bg_color: "red" },
            4.5: {name: "Dropped", bg_color: "red" },
            4.8: {name: "Cancelled", bg_color: "red" },
            4.9: {name: "Expelled", bg_color: "red" },
            5:   {name: "Auditor", bg_color: "green" },
            5.1: {name: "Non-Grad", bg_color: "yellow" },
            5.2: {name: "QA Review", bg_color: "yellow" },
            5.3: {name: "Transferred", bg_color: "yellow" }
        };

        return USER_STATUS[status] || {name: "", bg_color: "" };
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: December 13, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} key - Name of column to be sorted.
    * @author Jerwin Update by: Christian
    */
     requestSort = (key) => {
        let direction;
        let sort_config = { ...this.state.sort_config };

        direction = "caret-down";
        sort_config = { ...this.state.sort_config };

        /* This will update the direction of sort based on sort_config state */
        if ( sort_config && sort_config.key === key && sort_config.direction === "caret-down" ) {
            direction = "caret-up";
        }

        sort_config.key = key;
        sort_config.direction = direction;
        sort_config.title = null;

        this.setState({ selected_name_sort_config: undefined });
        
        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config, key, this.state.selected_view_type);
        });
    };

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: December 16, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} event="" - Requires to get the email element position.
    * @param {object} email="" - Email address of the student.
    * @author Jerwin, updated by Demy
    */
    copyEmailToClipboard = (event, email) => {
        let copy_clipboard_popover = {...this.state.copy_clipboard_popover};
        
        event.stopPropagation();
        copy_clipboard_popover.is_show = true;
        copy_clipboard_popover.position = event.target.getBoundingClientRect();

        this.setState({ copy_clipboard_popover }, () => {
            /* This will create temporary textarea to store selected text and save it to clipboard */ 
            const temp_element = document.createElement('textarea');
            temp_element.value = email;
            temp_element.setAttribute('readonly', '');
            temp_element.style.position = 'absolute';
            temp_element.style.left = '-9999px';
            document.body.appendChild(temp_element);
            temp_element.select();
            document.execCommand('copy');
            /* Once done it will remove the created temp textarea. */ 
            document.body.removeChild(temp_element);

            /* After 1 second it the Copy To Clipboard popover will automatically close/hide */ 
            setTimeout(() => {
                copy_clipboard_popover.is_show = false;
                this.setState({ copy_clipboard_popover });
            },1000);
        });
    }    

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: December 16, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Christian
    */
    getStudentProfileDetails = (user_id, applicant_id, program_type_id, location_id, student) => {
        this.props.getStudentProfileDetails({
            user_id: user_id, 
            applicant_id: applicant_id,
            user_bootcamp_id: student.user_bootcamp_id,
            next_stack_params: { program_type_ids:[program_type_id], location_ids:[location_id] },
            is_from_student_matching: false
        });

        this.setState({ is_show_student_profile_modal: true, student_profile_data: student, selected_applicant_id: applicant_id, is_update_student_profile: true });
    }    

    render() { 
        let { profile, table_head_columns, is_loading, students, belt_colors, exam_passing_score, is_fetching_filters, student_progress : {filter_dropdowns, by_course_filter_students: filter_students, program_attendance_requirement} } = this.props;
        let { copy_clipboard_popover, sort_config, ap_options } = this.state;
        let has_student_modal_access = checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_profile_modal.visibility");
        const [selected_programs, selected_course_start_date, selected_course] = filter_dropdowns;

        /* Attendance Info Popup Content */
        const attendance_tooltip_content = ["Attendance Requirement:", ...(program_attendance_requirement || [])].map(a => <div key={a}>{a}</div>);

        /* Get the the selected_sort as sort_config */
        sort_config = this.props?.selected_sort;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
                return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return ( 
            <React.Fragment>                
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }
                <div className="table_container" id="admin_student_progress_program_table">
                    <ColumnsVisibilityPopover table_head_columns={table_head_columns} onCustomizeTableColumns={this.props.onCustomizeTableColumns}/>

                    <StickyTable leftStickyColumnCount={1} borderWidth="0px" className={`${is_loading ? "table_loading" : "" }`}>
                        {/* Table HEAD */}
                        <Row>
                            { table_head_columns.map(table_head => 
                                (table_head.is_show === undefined || table_head.is_show) &&
                                    <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head?.sort_data && table_head.sort_data !== "track_history" ? this.requestSort(table_head.sort_data) : null}>
                                        { this.renderTableHead(table_head, students) }
                                        {table_head.name === "Attendance" &&
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={ 
                                                    <Tooltip>
                                                        {
                                                        (selected_course?.selected?.length && selected_course_start_date?.selected?.length && selected_programs?.selected?.length === 1 && filter_students?.length)
                                                            ? attendance_tooltip_content
                                                            : "Requirements vary by program"
                                                        }
                                                    </Tooltip>
                                                }>
                                                <FontAwesomeIcon icon={["fa", "info-circle"]} />
                                            </OverlayTrigger>
                                        }
                                        {/* Sort icon */}
                                        {(table_head.sort_data && table_head.sort_data !== "track_history"  && table_head.sort_data !== "name") && 
                                            <FontAwesomeIcon 
                                                className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`} 
                                                icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down" ]} />}
                                    </Cell>
                            )}
                        </Row>

                            
                       { (is_loading)
                            ?  !is_fetching_filters && 
                                <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            :  !is_fetching_filters && students && students.map( student => {
                                    let track_history = (student.track_history) ? JSON.parse(student.track_history) : undefined;
                                    let student_catalog = student?.catalog;
                                    let cache_progress_breakdown_json = (student.cache_progress_breakdown_json) ? JSON.parse(student.cache_progress_breakdown_json) : {};
                                    let core_assignment_to_date_breakdown = cache_progress_breakdown_json?.checklist_progress?.module_progress?.todo?.progress_percent?.within_schedule?.core || undefined;
                                    /* Can not be truthy since core_assignment_to_date_breakdown can have zero value */
                                    let core_assignment_data = (core_assignment_to_date_breakdown?.progress_to_date !== undefined && core_assignment_to_date_breakdown?.breakdown_to_date?.completed !== undefined) ? `${core_assignment_to_date_breakdown.progress_to_date}% (${core_assignment_to_date_breakdown.breakdown_to_date.completed}/${core_assignment_to_date_breakdown.breakdown_to_date.total})` : `0% (0/0)`;

                                    let cache_attendance_summary_json = (student.cache_attendance_summary_json) ? JSON.parse(student.cache_attendance_summary_json) : {};
                                    /* Can not be truthy since cache_to_date_present_percentage can have zero value */
                                    let attendance_to_date_breakdown = (cache_attendance_summary_json?.cache_to_date_present_percentage !== undefined) ? `${cache_attendance_summary_json.cache_to_date_present_percentage}% (${cache_attendance_summary_json.cache_to_date_total_present}/${cache_attendance_summary_json.cache_to_date_total_attendance})` : `0% (0/0)`;

                                    let ap_status = ap_options.filter(option => option.value === student.ap_status_id);
                                    let ap_status_label = (ap_status?.length) ? ap_status[0].label : "None" ;

                                    return (<Row key={student.id}>
                                                {table_head_columns.map(table_head =>

                                                    <React.Fragment>
                                                        {table_head.name === "Name" && table_head.is_show &&
                                                            
                                                            <Cell>
                                                                <div className="name" 
                                                                    onClick={(event)=> (has_student_modal_access) && this.getStudentProfileDetails(student.user_id, student.applicant_id, student.program_type_id, student.location_id, student, event) }>
                                                                    { student.first_name } { student.last_name }
                                                                    { !!student.feedback_count &&  <span className="note_icon" data-tab="notes"></span>}
                                                                </div>
                                                                <div className="email" onClick={(event) => this.copyEmailToClipboard(event, student.email_address, this)}>{ student.email_address }</div>
                                                            </Cell>
                                                        } 
                                                        
                                                        {table_head.name === "Program" && table_head.is_show &&
                                                            <Cell>{ student.program_type_name }</Cell>
                                                        } 
                                                        {table_head.name === "Course" && table_head.is_show &&
                                                            <Cell>{ student.cc_stack_name }</Cell>
                                                        } 
                                                        {table_head.name === "Instructor" && table_head.is_show &&
                                                            <Cell>{ student.instructor_first_name } { student.instructor_last_name?.[0] }</Cell>
                                                        } 
                                                        {table_head.name === "Status" && table_head.is_show &&
                                                            <Cell>
                                                                <div className={ this.renderUserStatus(student.status).bg_color +`_bg`}>
                                                                    <span>{ this.renderUserStatus(student.status).name }</span>
                                                                </div>
                                                            </Cell>
                                                        } 
                                                        {table_head.name === "Core" && table_head.is_show &&
                                                            <Cell>{ core_assignment_data }</Cell>
                                                        } 
                                                        {table_head.name === "Attendance" && table_head.is_show &&
                                                            <Cell>{ attendance_to_date_breakdown }</Cell>
                                                        } 
                                                        {table_head.name === "Program Start Date" && table_head.is_show &&
                                                            <Cell><Moment format="MMM DD, YYYY">{ student.program_start_date }</Moment></Cell>
                                                        } 
                                                        {table_head.name === "Exp Graduation Date" && table_head.is_show &&
                                                            <Cell><Moment format="MMM DD, YYYY">{ student.program_end_date }</Moment></Cell>
                                                        }
                                                        {table_head.name === "Grad Validation" && table_head.is_show &&
                                                            <Cell>{ student.grad_validation }</Cell>
                                                        }
                                                        {table_head.name === "Retake Status" && table_head.is_show &&
                                                            <Cell>{ student.retake_status }</Cell>
                                                        }
                                                        {table_head.name === "Academic Standing" && table_head.is_show &&
                                                            <Cell>{ ap_status_label }</Cell>
                                                        }
                                                        {table_head.name === "Catalog" && table_head.is_show &&
                                                            <Cell><a href={student_catalog?.catalog_link} target="_blank" class="gray_bg" rel="noopener noreferrer">{ student_catalog?.set_catalog }</a></Cell>
                                                        }
                                                        {table_head.name === "Course History" && table_head.is_show &&
                                                            <Cell>
                                                                 <ul className="list-unstyled track_history_list">
                                                                    { track_history && track_history.map( (track, index) => 
                                                                        track.stack_name
                                                                        ?   <TrackHistoryComponent student={student} track={track} belt_colors={belt_colors} exam_passing_score={exam_passing_score} />
                                                                        :   <li className="gray_bg">Stack {index + 1}</li>
                                                                    )}
                                                                </ul>
                                                            </Cell>
                                                        }
                                                    </React.Fragment> 
                                                )}
                                            </Row>) })
                        }

                        { (is_fetching_filters) &&
                            <div id="no_results_found">Preparing filters....</div>
                        }

                        { (is_loading === false && students.length === 0 && is_fetching_filters === false) &&
                            <div id="no_results_found">No results found.</div>
                        }
                    </StickyTable>
                </div>

                {
                    <StudentAccessProfile toggleShowModal={ () => toggleShowModal(this, "is_show_student_profile_modal", false) }
                        student_data={ this.state.student_profile_data }
                        show={ this.state.is_show_student_profile_modal }
                        changeProgramStatus={ this.changeProgramStatus }
                        admin_page={ ADMIN_PAGES.stack_dashboard }
                    />
                }

            </React.Fragment>
        );
    }
}
 
let { getStudentProfileDetails, updateStudentProgramAccess } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "student_access"], { getStudentProfileDetails, updateStudentProgramAccess });
 
export default connect(mapStateToProps, mapDispatchToProps)(ProgramTableDataComponent);
